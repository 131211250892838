.result {
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #eee;
  box-shadow: 0 6px 2px -2px #aaa;
  h2 {
    text-align: center;
    margin-bottom: 1rem;
    small {
      display: block;
    }
  }
}