$enable-responsive-font-sizes: true;
@import "~bootstrap/scss/bootstrap";

body {
  background-color: #f5f5f5;
}

.clickable {
  cursor: pointer;
}

.app-layout {
  height: 100vh;
  padding: 10% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}