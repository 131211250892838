.question {
  width: 100%;
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  h2 {
    text-align: center;
  }
}

.answer {
  padding: 0.5rem;
  button {
    width: 100%;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 1rem;
    padding: 1rem 2rem;
    box-shadow: 0 6px 2px -2px #aaa;
    transition: transform 0.1s, box-shadow 0.1s, background-color 0.5s;
    font-size: 1rem;
    &:focus {
      outline: none;
    }
    &:active {
      box-shadow: none;
      transform: translateY(6px);
    }
    &:hover {
      background-color: #eee;
    }
  }
}